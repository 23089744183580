import React, { useState } from "react";
import { Helmet } from "react-helmet";
import * as XLSX from "xlsx";
import { motion } from "framer-motion";
import { Container, Card, Row, Col } from "react-bootstrap";
import "./ExcelToCsvConverter.css";

const ExcelToCsvJsonConverter = () => {
  const [csvData, setCsvData] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension !== "xls" && fileExtension !== "xlsx") {
      alert("Please upload a valid Excel file (.xls or .xlsx)");
      return;
    }

    setLoading(true); // Set loading to true

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      let workbook;
      try {
        workbook = XLSX.read(data, { type: "array" });
      } catch (error) {
        console.error("Error reading file:", error);
        setLoading(false); // Set loading to false
        return;
      }

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      const csv = XLSX.utils.sheet_to_csv(worksheet);

      setCsvData(csv);
      setJsonData(JSON.stringify(json, null, 2));
      setLoading(false); // Set loading to false
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDownload = (data, fileType) => {
    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileType === "text/csv" ? "data.csv" : "data.json";
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Helmet>
        <title>
          Excel to CSV / JSON Converter - Convert Files Easily | TechPulsar
        </title>
        <meta
          name="description"
          content="Convert Excel files to CSV or JSON formats instantly with TechPulsar's Excel to CSV/JSON Converter tool. Easy and fast file conversion."
        />
        <meta
          name="keywords"
          content="Excel to CSV, Excel to JSON, file converter, convert Excel, CSV converter, JSON converter, TechPulsar tools"
        />
        <meta
          property="og:title"
          content="Excel to CSV / JSON Converter - Convert Files Easily | TechPulsar"
        />
        <meta
          property="og:description"
          content="Easily convert Excel files to CSV or JSON formats with TechPulsar's file converter tool. Speed up your workflow with quick conversions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://techpulsar.com/excel-to-csv-json-converter"
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/images/excel-csv-json-converter-tool.png"
        />
      </Helmet>

      <motion.div
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, scale: 0.4 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <Container className="converter-container bg-dark">
          <h1 className="text-light">Excel To CSV/JSON Converter</h1>
          <div className="relative">
            <input
              type="file"
              accept=".xls,.xlsx"
              onChange={handleFileChange}
              className="custom-file-input"
            />
          </div>
        </Container>
      </motion.div>

      <Container>
        <Row>
          {loading ? (
            <Col md={12} className="spinner-container">
              <div className="spinner"></div>
            </Col>
          ) : (
            <>
              {csvData && (
                <Col md={6} className="mb-4">
                  <motion.div
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <Card bg="dark" text="light">
                      <Card.Body>
                        <Card.Title>CSV Data</Card.Title>
                        <textarea
                          value={csvData}
                          readOnly
                          className="csv-textarea form-control"
                          placeholder="CSV data will appear here..."
                        />
                        <button
                          onClick={() => handleDownload(csvData, "text/csv")}
                          className="download-button btn btn-primary mt-2"
                        >
                          Download CSV
                        </button>
                      </Card.Body>
                    </Card>
                  </motion.div>
                </Col>
              )}
              {jsonData && (
                <Col md={6} className="mb-4">
                  <motion.div
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.5 }}
                  >
                    <Card bg="dark" text="light">
                      <Card.Body>
                        <Card.Title>JSON Data</Card.Title>
                        <textarea
                          value={jsonData}
                          readOnly
                          className="json-textarea form-control"
                          placeholder="JSON data will appear here..."
                        />
                        <button
                          onClick={() =>
                            handleDownload(jsonData, "application/json")
                          }
                          className="download-button btn btn-primary mt-2"
                        >
                          Download JSON
                        </button>
                      </Card.Body>
                    </Card>
                  </motion.div>
                </Col>
              )}
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default ExcelToCsvJsonConverter;
