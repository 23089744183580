import { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { SketchPicker } from "react-color";
import InputUrl from "./InputUrl";
import InputFileImg from "./InputFileImg";

function FormQrCustom({
  qrRef,
  url,
  qrColor,
  qrBgColor,
  noImg,
  setUrl,
  setQrColor,
  setQrBgColor,
  setCustomImg,
  setNoImg,
  handleQrReset,
}) {
  const httpRgx = /^https?:\/\//;

  const [downloaded, setDownloaded] = useState(false);
  const [displayQrColorPicker, setDisplayQrColorPicker] = useState(false);
  const [displayBgColorPicker, setDisplayBgColorPicker] = useState(false);

  useEffect(() => {
    if (downloaded) {
      const msg = setTimeout(() => setDownloaded(false), 3500);
      return () => clearTimeout(msg);
    }
  }, [downloaded]);

  const handleQrCustom = (color) => setQrColor(color.hex),
    handleQrBgCustom = (color) => setQrBgColor(color.hex);

  const downloadQrCode = (e) => {
    e.preventDefault();

    const qrCanvas = qrRef.current.querySelector("canvas"),
      qrImage = qrCanvas.toDataURL("image/png"),
      qrAnchor = document.createElement("a"),
      fileName = url.replace(httpRgx, "").trim();
    qrAnchor.href = qrImage;
    qrAnchor.download = fileName + "_QrCodeByTechpulsar.png";
    document.body.appendChild(qrAnchor);
    qrAnchor.click();
    document.body.removeChild(qrAnchor);

    handleQrReset();
    setDownloaded(true);
  };

  return (
    <Form
      onSubmit={downloadQrCode}
      className="bg-dark p-4 rounded shadow mx-auto"
    >
      <h1 className="text-center mb-4">Generate Your QR Code</h1>

      <Form.Group className="mb-3">
        <InputUrl
          url={url}
          setUrl={setUrl}
          httpRgx={httpRgx}
          className="custom-url-input"
        />
      </Form.Group>

      <div className="row mt-3">
        {/* QR Code Color Picker */}
        <Form.Group className="col mb-3" style={{ position: "relative" }}>
          <Form.Label>QR Code Color</Form.Label>
          <div className="color-picker-wrapper">
            <Button
              variant="secondary"
              onClick={() => setDisplayQrColorPicker(!displayQrColorPicker)}
              style={{ backgroundColor: qrColor, width: 50, height: 50 }}
            >
              {" "}
            </Button>
            {displayQrColorPicker && (
              <div className="popover">
                <div
                  className="cover"
                  onClick={() => setDisplayQrColorPicker(false)}
                />
                <SketchPicker color={qrColor} onChange={handleQrCustom} />
              </div>
            )}
          </div>
        </Form.Group>

        {/* Background Color Picker */}
        <Form.Group className="col mb-3" style={{ position: "relative" }}>
          <Form.Label>Background Color</Form.Label>
          <div className="color-picker-wrapper">
            <Button
              variant="secondary"
              onClick={() => setDisplayBgColorPicker(!displayBgColorPicker)}
              style={{ backgroundColor: qrBgColor, width: 50, height: 50 }}
            >
              {" "}
            </Button>
            {displayBgColorPicker && (
              <div className="popover">
                <div
                  className="cover"
                  onClick={() => setDisplayBgColorPicker(false)}
                />
                <SketchPicker color={qrBgColor} onChange={handleQrBgCustom} />
              </div>
            )}
          </div>
        </Form.Group>
      </div>

      <Form.Group className="mb-4 mt-2">
        <div
          className="d-flex"
        >
          <Form.Label className="me-2">Without Image</Form.Label>
          <div className="input-file-wrapper">
            <InputFileImg
              noImg={noImg}
              setNoImg={setNoImg}
              setCustomImg={setCustomImg}
              className="custom-file-input"
            />
          </div>
        </div>
      </Form.Group>

      <Button type="submit" variant="primary" className="w-100">
        Download QR Code
      </Button>

      {downloaded && (
        <Alert variant="success" className="text-center mt-3">
          QR Code downloaded successfully!
        </Alert>
      )}
    </Form>
  );
}

export default FormQrCustom;
