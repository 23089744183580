import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Tabs,
  Tab,
  Card,
} from "react-bootstrap";
import { motion } from "framer-motion";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet";
import "./ImageResizer.css";

const units = ["pixels", "inches"];
const DPI = 96; // Default DPI (Dots Per Inch)
const formats = ["image/jpeg", "image/png"]; // Supported formats
const MAX_CANVAS_SIZE = 4000;
const inches = MAX_CANVAS_SIZE / DPI;

const ImageResizer = () => {
  const [originalImage, setOriginalImage] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [resizeBy, setResizeBy] = useState("dimension");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [unit, setUnit] = useState("pixels");
  const [percentage, setPercentage] = useState(100);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(formats[0]);

  const [originalDimensions, setOriginalDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [resizedDimensions, setResizedDimensions] = useState({
    width: 0,
    height: 0,
  });

  const convertToPixels = (value, unit) => {
    if (unit === "inches") {
      return value * DPI;
    }
    return value; // If in pixels, no conversion needed
  };

  const convertFromPixels = (pixels, unit) => {
    if (unit === "inches") {
      return pixels / DPI;
    }
    return pixels; // If in pixels, no conversion needed
  };

  const handleUnitChange = (newUnit) => {
    if (newUnit === unit) return; // No change in unit
    const convertedWidth = convertFromPixels(
      convertToPixels(width, unit),
      newUnit
    ).toFixed(2);
    const convertedHeight = convertFromPixels(
      convertToPixels(height, unit),
      newUnit
    ).toFixed(2);
    setWidth(convertedWidth);
    setHeight(convertedHeight);
    setUnit(newUnit);
  };

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setOriginalDimensions({ width: img.width, height: img.height });
        setOriginalImage(img.src);
        simulateProgress(); // Start the progress bar simulation
      };
    }
  };

  const simulateProgress = () => {
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 25;
      setProgress(progressValue);
      if (progressValue >= 100) {
        clearInterval(interval);
      }
    }, 300); // Increment progress every 375ms for a total of 1500ms
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
  });

  const resizeImage = (event) => {
    event.preventDefault();
    setError(null);

    if (!originalImage) {
      setError("Please upload an image before resizing.");
      return;
    }

    if (resizeBy === "dimension" && (!width || !height)) {
      setError("Please enter both width and height for resizing by dimension.");
      return;
    }

    // Convert width and height to pixels based on the selected unit
    const newWidth = convertToPixels(parseFloat(width), unit);
    const newHeight = convertToPixels(parseFloat(height), unit);

    // Check if the new dimensions exceed the maximum canvas size
    if (newWidth > MAX_CANVAS_SIZE || newHeight > MAX_CANVAS_SIZE) {
      setError(
        `Image size too large. The maximum supported size is ${MAX_CANVAS_SIZE}pixels or ${inches.toFixed(
          0
        )}inches.`
      );
      return;
    }

    setLoading(true); // Show loader
    setTimeout(() => {
      const img = new Image();
      img.src = originalImage;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let newWidth, newHeight;

        if (resizeBy === "dimension") {
          // Convert width and height to pixels based on the selected unit
          newWidth = convertToPixels(parseFloat(width), unit);
          newHeight = convertToPixels(parseFloat(height), unit);
        } else if (resizeBy === "percentage") {
          newWidth = (originalDimensions.width * percentage) / 100;
          newHeight = (originalDimensions.height * percentage) / 100;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob((blob) => {
          const resizedUrl = URL.createObjectURL(blob);
          setResizedImage(resizedUrl);
          setResizedDimensions({ width: newWidth, height: newHeight });
          setLoading(false); // Hide loader
        }, selectedFormat);
      };
    }, 1500); // Simulate resizing process for 1500ms
  };

  return (
    <motion.div
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, scale: 0.4 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
    >
      <Container className="my-4 image-resizer-card bg-dark">
        <Helmet>
          {/* Primary Meta Tags */}
          <title>
            Image Resizer | TechPulsar - Resize Your Images Online for Free
          </title>
          <meta
            name="description"
            content="Resize your images quickly and easily with TechPulsar's online image resizer tool. Adjust dimensions and formats without losing quality."
          />
          <meta
            name="keywords"
            content="image resizer, resize images online, free image resizer, TechPulsar tools, image resizing tool"
          />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://tools.techpulsar.com/image-resizer"
          />
          <meta
            property="og:title"
            content="Image Resizer Tool - Resize Your Images Online for Free | TechPulsar"
          />
          <meta
            property="og:description"
            content="TechPulsar's image resizer tool allows you to resize images online for free. Adjust dimensions and formats without losing quality. Perfect for web developers and designers."
          />
          <meta
            property="og:image"
            content="https://tools.techpulsar.com/assets/image-resizer-og.jpg"
          />
          <meta property="og:site_name" content="TechPulsar Tools" />
        </Helmet>
        <h1 className="text-center mb-4">Image Resizer</h1>
        <Row>
          <Col md={1}></Col>
          <Col md={progress > 0 ? 6 : 12}>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="dropzone border border-dashed p-4 text-center rounded"
            >
              <input {...getInputProps()} />
              <p className="mb-0">
                Drag & drop an image here, or click to select one
              </p>
            </div>
            {progress > 0 && (
              <div className="progress-bar">
                <div className="progress-bar-fill"></div>
              </div>
            )}
            {originalImage && progress === 100 && (
              <Card className="mt-3">
                <Card.Body>
                  <Card.Title>Image Uploaded</Card.Title>
                  <Row>
                    <Col className="mt-2">
                      <img
                        variant="top"
                        src={originalImage}
                        style={{ width: 100 }}
                        alt="original"
                      />
                      <p className="mt-2 text-black">
                        Image dimensions: {originalDimensions.width} x{" "}
                        {originalDimensions.height}
                      </p>
                    </Col>
                    <Col>
                      {resizedImage && !loading && (
                        <Row className="text-center mt-1">
                          <Col>
                            <img
                              src={resizedImage}
                              alt="Resized"
                              className="img-fluid rounded"
                              style={{ width: 100 }}
                            />
                            <div className="mt-2">
                              <p className="text-black">
                                Resized Dimensions:{" "}
                                {resizedDimensions.width.toFixed(0)} x{" "}
                                {resizedDimensions.height.toFixed(0)} {unit}
                              </p>
                            </div>
                            {/* <Dropdown className="mt-3">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              Download as{" "}
                              {selectedFormat.split("/")[1].toUpperCase()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {formats.map((format) => (
                                <Dropdown.Item
                                  key={format}
                                  onClick={() => setSelectedFormat(format)}
                                >
                                  {format.split("/")[1].toUpperCase()}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown> */}
                            {!loading && (
                              <Button
                                variant="success"
                                className="mt-3"
                                onClick={() => {
                                  const link = document.createElement("a");
                                  link.href = resizedImage;
                                  link.download = `resized-image.${
                                    selectedFormat.split("/")[1]
                                  }`;
                                  link.click();
                                }}
                              >
                                Download
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Col>
          {originalImage && progress === 100 && (
            <Col md={4}>
              {error && <Alert variant="danger">{error}</Alert>}
              <div className="d-flex flex-column">
                <Tabs
                  id="resize-tabs"
                  activeKey={resizeBy}
                  onSelect={(k) => setResizeBy(k)}
                  className="mb-3"
                >
                  <Tab eventKey="dimension" title="Resize By Dimension">
                    <Row className="mt-3">
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Width</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Width"
                            value={width}
                            onChange={(e) => setWidth(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Height</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Height"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Unit</Form.Label>
                          <Form.Control
                            as="select"
                            value={unit}
                            onChange={(e) => handleUnitChange(e.target.value)}
                          >
                            {units.map((unit) => (
                              <option key={unit} value={unit}>
                                {unit}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="percentage" title="Resize By Percentage">
                    <Form.Group className="mb-3">
                      <Form.Label>Percentage</Form.Label>
                      <Form.Control
                        type="range"
                        min="10"
                        max="100"
                        step="1"
                        value={percentage}
                        onChange={(e) => setPercentage(Number(e.target.value))}
                      />
                      <div className="text-center">{percentage}%</div>
                    </Form.Group>
                  </Tab>
                </Tabs>

                <Button
                  variant="primary"
                  onClick={resizeImage}
                  disabled={loading}
                >
                  {loading ? "Resizing..." : "Resize Image"}
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </motion.div>
  );
};

export default ImageResizer;
