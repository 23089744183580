import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";

export default function Footer() {
  return (
    <motion.footer
      className="bg-dark text-light py-2"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            className="text-center text-md-start mb-2 mb-md-0"
          >
            <div style={{ fontSize: 13 }}>
              Powered By:{" "}
              <span>
                <a
                  href="https://www.techpulsar.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-light"
                  style={{ textDecoration: "none" }}
                >
                  TechPulsar
                </a>
              </span>
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <div style={{ fontSize: 13 }}>
              © 2024 TechPulsar Tools V1. All rights reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </motion.footer>
  );
}
