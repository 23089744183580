function InputUrl({ url, setUrl, httpRgx, className }) {
	const handleUrl = e => setUrl(e.target.value),
			handleClearUrl = () => setUrl('');
	
	const handleValidateUrl = () => {
		if(url && !httpRgx.test(url)) {
			setUrl('http://'+url);
		}
	}

	return(
		<div className="relative">
			{/* <label htmlFor="url">Enter URL</label> */}
			
			<input
				id="url"
				name="url"
				type="text"
				value={url}
				onChange={handleUrl}
				onBlur={handleValidateUrl}
				placeholder="Add URL or Text for Qr Code generation"
				required
				className={className}
			/>
			
			{/* {url &&
				<button className="clear-btn" onClick={handleClearUrl}>x</button>
			} */}
		</div>
	);
}

export default InputUrl;