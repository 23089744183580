import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import FormQrCustom from "../../components/FormQrCustom";
import QrCard from "../../components/QrCard";
import "./QrCode.css";

function QrCodeGen() {
  const qrRef = useRef();

  const [url, setUrl] = useState(""),
    [qrColor, setQrColor] = useState("#ffffff"),
    [qrBgColor, setQrBgColor] = useState("#2c7dfa"),
    [customImg, setCustomImg] = useState(""),
    [noImg, setNoImg] = useState(true);

  const handleQrReset = () => {
    setUrl("");
    setQrColor("#ffffff");
    setQrBgColor("#2c7dfa");
    setCustomImg("");
    setNoImg(false);
  };

  return (
    <motion.div
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, scale: 0.4 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
      className="container py-5"
    >
      <Helmet>
        <title>QR Code Generator - TechPulsar</title>
        <meta
          name="description"
          content="Create custom QR codes with TechPulsar's QR Code Generator tool. Easy to use and fully customizable."
        />
        <meta
          name="keywords"
          content="QR code generator, create QR codes, custom QR codes, TechPulsar tools, qr code with image, qr code with logo, logo qr code"
        />
        <meta
          property="og:title"
          content="QR Code Generator Tool - TechPulsar"
        />
        <meta
          property="og:description"
          content="Create custom QR codes with TechPulsar's QR Code Generator tool. Easy to use and fully customizable."
        />
        <meta
          property="og:image"
          content="https://yourdomain.com/path-to-qr-code-generator-thumbnail.jpg"
        />
        <meta
          property="og:url"
          content="https://tools.techpulsar.com/qr-code-generator"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="TechPulsar Tools" />
      </Helmet>

      <div className="row">
        <div className="col-md-8">
          <FormQrCustom
            qrRef={qrRef}
            url={url}
            qrColor={qrColor}
            qrBgColor={qrBgColor}
            noImg={noImg}
            setUrl={setUrl}
            setQrColor={setQrColor}
            setQrBgColor={setQrBgColor}
            setCustomImg={setCustomImg}
            setNoImg={setNoImg}
            handleQrReset={handleQrReset}
          />
        </div>
        <div className="col-md-4">
          <QrCard
            qrRef={qrRef}
            url={url}
            qrColor={qrColor}
            bgColor={qrBgColor}
            customImg={customImg}
            noImg={noImg}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default QrCodeGen;
