import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ColorPicker, useColor } from "react-color-palette";
import { FaRegCopy } from "react-icons/fa";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-color-palette/css";
import "./ColorPicker.css";

const ColorPickerComponent = () => {
  const [color, setColor] = useColor("#ffffff");
  const [activeTab, setActiveTab] = useState("RGB");
  const [r, setR] = useState(255);
  const [g, setG] = useState(255);
  const [b, setB] = useState(255);
  const [a, setA] = useState(1);

  const handleColorChange = (newColor) => {
    setColor(newColor);
    setR(newColor.rgb.r);
    setG(newColor.rgb.g);
    setB(newColor.rgb.b);
    setA(newColor.rgb.a);
  };

  const updateColor = (r, g, b, a) => {
    const newColor = {
      hex: `rgba(${r}, ${g}, ${b}, ${a})`,
      rgb: { r, g, b, a },
      hsl: color.hsl,
      hsv: color.hsv,
    };
    setR(r);
    setG(g);
    setB(b);
    setA(a);
    setColor(newColor);
  };

  const formatNumber = (num) => num.toFixed(0);
  const formatPercentage = (num) => `${(num * 100).toFixed(0)}%`;
  const formatAngle = (num) => `${num.toFixed(0)}°`;

  const getColorValue = () => {
    switch (activeTab) {
      case "RGB":
        return `RGB(${formatNumber(r)}, ${formatNumber(g)}, ${formatNumber(
          b
        )})`;
      case "HSV":
        return `HSV(${formatAngle(color.hsv?.h || 0)}, ${formatPercentage(
          color.hsv?.s / 100 || 0
        )}, ${formatPercentage(color.hsv?.v / 100 || 0)})`;
      case "HSL":
        return `HSL(${formatAngle(color.hsl?.h || 0)}, ${formatPercentage(
          color.hsl?.s || 0
        )}, ${formatPercentage(color.hsl?.l || 0)})`;
      case "CMYK":
        const c = (1 - r / 255) * 100;
        const m = (1 - g / 255) * 100;
        const y = (1 - b / 255) * 100;
        const k = Math.min(c, m, y);
        return `CMYK(${formatPercentage((c - k) / 100)}, ${formatPercentage(
          (m - k) / 100
        )}, ${formatPercentage((y - k) / 100)}, ${formatPercentage(k / 100)})`;
      default:
        return "";
    }
  };

  const handleCopy = (value, format) => {
    toast.success(`${format} copied!`, {
      position: "bottom-right",
      autoClose: 2000,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Color Picker Tool - Pick and Customize Colors Easily | TechPulsar
        </title>
        <meta
          name="description"
          content="Use TechPulsar's Color Picker to select, adjust, and customize colors for your design projects. Simple and fast color picking with RGB, HEX, and HSL options."
        />
        <meta
          name="keywords"
          content="color picker, pick colors, color customization, HEX to RGB, RGB to HEX, HSL colors, design tool, TechPulsar tools"
        />
        <meta
          property="og:title"
          content="Color Picker Tool - Pick and Customize Colors Easily | TechPulsar"
        />
        <meta
          property="og:description"
          content="Select and customize colors easily with TechPulsar's Color Picker tool. Supports RGB, HEX, HSL formats for all your design needs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://techpulsar.com/color-picker" />
        <meta
          property="og:image"
          content="https://techpulsar.com/images/color-picker-tool.png"
        />
      </Helmet>
      <motion.div
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, scale: 0.4 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <Container className="color-picker-container bg-dark">
          <h1>Color Picker</h1>
          <div className="row">
            <div className="col-md-3">
              <div className="">
                <div
                  className="color-display"
                  style={{ backgroundColor: color.hex }}
                ></div>

                <p
                  style={{
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: "-3%",
                    fontWeight: "700",
                    marginTop: "5%",
                  }}
                >
                  Hex Code
                </p>
                <div className="color-format-values">
                  <input value={color.hex} className="input" readOnly />
                  <CopyToClipboard
                    text={color.hex}
                    onCopy={() => handleCopy(color.hex, "Hex")}
                    className="copy-icon"
                    style={{
                      padding: "2%",
                      borderRadius: "20%",
                    }}
                  >
                    <FaRegCopy color="#fff" size={20} />
                  </CopyToClipboard>
                </div>

                <p
                  style={{
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: "0%",
                    fontWeight: "700",
                    marginTop: "5%",
                  }}
                >
                  Other Colors Format
                </p>
                <div className="color-picker-tabs">
                  {["RGB", "HSV", "HSL", "CMYK"].map((tab) => (
                    <div
                      key={tab}
                      className={`color-picker-tab ${
                        activeTab === tab ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>

                <div className="color-format-values">
                  <div className="color-value-display">
                    <input value={getColorValue()} className="input" readOnly />
                  </div>
                  <CopyToClipboard
                    className="copy-icon"
                    text={getColorValue()}
                    onCopy={() => handleCopy(getColorValue(), activeTab)}
                    style={{
                      padding: "2%",
                      borderRadius: "30%",
                    }}
                  >
                    <FaRegCopy color="#fff" size={20} />
                  </CopyToClipboard>
                </div>
              </div>

              {/* <p
            style={{
              color: "#fff",
              textAlign: "left",
              marginBottom: "-4%",
              fontWeight: "700",
              marginTop: "5%",
            }}
          >
            Edit Colors
          </p>
          <div className="color-edit-settings">
            <div className="color-edit-row">
              <span>R:</span>
              <input
                type="range"
                min="0"
                max="255"
                value={r}
                onChange={(e) => updateColor(Number(e.target.value), g, b, a)}
              />
              <input
                type="number"
                min="0"
                max="255"
                value={r}
                onChange={(e) => updateColor(Number(e.target.value), g, b, a)}
              />
            </div>
            <div className="color-edit-row">
              <span>G:</span>
              <input
                type="range"
                min="0"
                max="255"
                value={g}
                onChange={(e) => updateColor(r, Number(e.target.value), b, a)}
              />
              <input
                type="number"
                min="0"
                max="255"
                value={g}
                onChange={(e) => updateColor(r, Number(e.target.value), b, a)}
              />
            </div>
            <div className="color-edit-row">
              <span>B:</span>
              <input
                type="range"
                min="0"
                max="255"
                value={b}
                onChange={(e) => updateColor(r, g, Number(e.target.value), a)}
              />
              <input
                type="number"
                min="0"
                max="255"
                value={b}
                onChange={(e) => updateColor(r, g, Number(e.target.value), a)}
              />
            </div>
            <div className="color-edit-row">
              <span>A:</span>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={a}
                onChange={(e) => updateColor(r, g, b, Number(e.target.value))}
              />
              <input
                type="number"
                min="0"
                max="1"
                step="0.01"
                value={a}
                onChange={(e) => updateColor(r, g, b, Number(e.target.value))}
              />
            </div>
          </div> */}
            </div>
            <div className="col-md-9">
              <ColorPicker
                color={color}
                hideAlpha={false}
                hideInput={true}
                className="color-picker"
                onChange={handleColorChange}
              />
              <ToastContainer />
            </div>
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default ColorPickerComponent;
