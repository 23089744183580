import React from "react";

function InputFileImg({ noImg, setNoImg, setCustomImg, className }) {
  const handleImage = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCustomImg(reader.result);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const checkboxStyle = {
    width: "1rem",
    height: "1rem",
    cursor: "pointer",
    borderRadius: "0.25rem",
    border: "1px solid #ccc",
    accentColor: "#45a247", // For modern browsers supporting accent-color
    transform: "scale(1.2)", // Increase the size visually
  };

  return (
    <div
      style={{
        display: "flex",
        alignSelf: "flex-start",
      }}
    >
      <input
        id="noImg"
        name="noImg"
        type="checkbox"
        checked={noImg}
        onChange={() => setNoImg(!noImg)}
        style={checkboxStyle}
      />
      <label htmlFor="noImg">Without Image</label>
      {!noImg && (
        <div style={{ width: "100%" }}>
          {/* <label htmlFor="file">Upload custom image</label> */}
          <input
            id="file"
            name="file"
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleImage}
            className={className}
          />
        </div>
      )}
    </div>
  );
}

export default InputFileImg;
