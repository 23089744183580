import React from "react";
import { motion } from "framer-motion";
import { Card as BootstrapCard, Col, Row } from "react-bootstrap";
import tools from "../toolsList/tools.json";

export default function Card() {
  return (
    <Row className="g-4">
      {tools.map((tool, index) => (
        <Col key={index} xs={12} md={6} lg={3}>
          <a href={tool.link} style={{ textDecoration: "none" }}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
            >
              <BootstrapCard className="border-0 shadow-sm bg-dark">
                <BootstrapCard.Img
                  variant="top"
                  src={tool.image}
                  alt={tool.name}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                <BootstrapCard.Body>
                  <BootstrapCard.Title style={{ color: "#fff" }}>
                    {tool.name}
                  </BootstrapCard.Title>
                  <p
                    style={{
                      color: "#ccc",
                      fontSize: 14,
                    }}
                  >
                    {tool.description}
                  </p>
                </BootstrapCard.Body>
              </BootstrapCard>
            </motion.div>
          </a>
        </Col>
      ))}
    </Row>
  );
}
