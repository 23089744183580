import { QRCodeCanvas } from "qrcode.react";
import defaultImage from "../logo.svg";

function QrCard({ qrRef, url, bgColor, qrColor, customImg, noImg }) {
  let imgCustom = defaultImage;

  noImg
    ? (imgCustom = null)
    : customImg
    ? (imgCustom = customImg)
    : (imgCustom = "./logo-apple-icon192.png");

  return (
    <article className="p-3 rounded shadow-sm bg-dark">
      <div
        ref={qrRef}
        style={{
          backgroundColor: bgColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <QRCodeCanvas
          size={250}
          value={url ? url : "techpulsar.com"}
          bgColor={bgColor}
          fgColor={qrColor}
          level="H"
          includeMargin
          imageSettings={{
            src: imgCustom,
            height: 45,
            width: 45,
            excavate: true,
          }}
        />
      </div>
      {url ? (
        <h2 className="word-wrap text-center mt-3">{url}</h2>
      ) : (
        <p className="text-center mt-3" style={{ fontSize: 20 }}>
          Enter your website URL and generate a custom QR Code in just a few
          clicks.
        </p>
      )}
    </article>
  );
}

export default QrCard;
