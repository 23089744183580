import React, { useState } from "react";
import { motion } from "framer-motion";
import imageCompression from "browser-image-compression";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet";
import { ReactComponent as DownloadIcon } from "../../assets/download-square.svg";
import "./ImageCompressor.css";

const ImageCompressor = () => {
  const [originalImage, setOriginalImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [imageType, setImageType] = useState("png");
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);
  const [compressionPercentage, setCompressionPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [originalFileName, setOriginalFileName] = useState("");
  const [compressedFileName, setCompressedFileName] = useState("");
  const [showCompressedImage, setShowCompressedImage] = useState(false);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    setOriginalFileName(file.name); // Set original file name
    setOriginalSize(file.size);
    setOriginalImage(URL.createObjectURL(file));
    setLoading(true); // Show progress bar

    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true, // Use Web Worker
      fileType: `image/${imageType}`, // Set the output file type
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const compressedImageUrl = URL.createObjectURL(compressedFile);
      setCompressedImage(compressedImageUrl);
      setCompressedSize(compressedFile.size);
      setCompressedFileName(`compressed-image.${imageType}`); // Set compressed file name

      const percentage = ((file.size - compressedFile.size) / file.size) * 100;
      setCompressionPercentage(percentage.toFixed(2));

      setTimeout(() => {
        setLoading(false); // Hide progress bar
      }, 1500);

      // Delay showing the compressed image
      setTimeout(() => {
        setShowCompressedImage(true);
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      console.error("Error compressing image:", error);
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = compressedImage;
    link.download = compressedFileName;
    link.click();
  };

  // Calculate the stroke-dashoffset for the circular progress bar
  const circumference = 2 * Math.PI * 45; // Radius is 45
  const offset = circumference - (compressionPercentage / 100) * circumference;

  return (
    <motion.div
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, scale: 0.4 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
      className="compressor-container bg-dark"
    >
      <Helmet>
        <title>Image Compressor Tool - TechPulsar</title>
        <meta
          name="description"
          content="Compress images online with TechPulsar's Image Compressor tool. Reduce file size without losing quality."
        />
        <meta
          name="keywords"
          content="image compressor, online image compression, reduce image file size, TechPulsar tools"
        />
        <meta
          property="og:title"
          content="Image Compressor Tool - TechPulsar"
        />
        <meta
          property="og:description"
          content="Compress images online with TechPulsar's Image Compressor tool. Reduce file size without losing quality."
        />
        <meta
          property="og:image"
          content="https://yourdomain.com/path-to-image-compressor-thumbnail.jpg"
        />
        <meta
          property="og:url"
          content="https://tools.techpulsar.com/image-compression"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="TechPulsar Tools" />
      </Helmet>

      <h1 style={{ fontSize: 24 }} className="text-center mb-4">
        Image Compression Tool
      </h1>
      <div className="input-container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Drag & drop an image here, or click to select one</p>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3">
          <h6
            className="mb-2 mb-md-0 mt-md-4 ml-1"
            style={{ color: "#fff", width: "100%", textAlign: "left" }}
          >
            Select Output Image Format
          </h6>
          <div className="button-group d-flex justify-content-center justify-content-md-end w-100 w-md-auto">
            <div
              className={`image-type-button ${
                imageType === "jpeg" ? "selected" : ""
              }`}
              onClick={() => setImageType("jpeg")}
            >
              JPEG
            </div>
            <div
              className={`image-type-button ${
                imageType === "png" ? "selected" : ""
              }`}
              onClick={() => setImageType("png")}
            >
              PNG
            </div>
            <div
              className={`image-type-button ${
                imageType === "webp" ? "selected" : ""
              }`}
              onClick={() => setImageType("webp")}
            >
              WEBP
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="preview-container gap-5">
        {originalImage && (
          <div className="image-preview">
            <h5 style={{ color: "#fff" }}>Original Image:</h5>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img
                src={originalImage}
                alt="Original"
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "contain",
                  backgroundColor: "#fff",
                }}
              />
              <div style={{ padding: "2%", color: "#fff" }}>
                <div>Size: {(originalSize / 1024).toFixed(2)} KB</div>
                <div>
                  File:{" "}
                  {`${originalFileName
                    .split(".")
                    .slice(0, -1)
                    .join(".")
                    .substring(0, 7)}...${originalFileName.split(".").pop()}`}
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading && showCompressedImage && compressedImage && (
          <>
            <div className="progress-circle-container">
              <div className="progress-circle">
                <svg width="100%" height="100%" viewBox="0 0 100 100">
                  <circle
                    className="background-circle"
                    cx="50"
                    cy="50"
                    r="45"
                  />
                  <circle
                    className="progress-circle-fill"
                    cx="50"
                    cy="50"
                    r="45"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                  />
                </svg>
                <div className="progress-circle-text">
                  <p style={{ fontSize: 11 }}>Compressed</p>
                  {compressionPercentage}%
                </div>
              </div>
            </div>

            <div className="image-preview">
              <h5 style={{ color: "#fff" }}>Compressed Image:</h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  src={compressedImage}
                  alt="Compressed"
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    backgroundColor: "#fff",
                  }}
                />
                <div style={{ padding: "2%", color: "#fff" }}>
                  <div>Size: {(compressedSize / 1024).toFixed(2)} KB</div>
                  <button
                    style={{
                      backgroundColor: "#fff",
                      width: 40,
                      height: 40,
                      padding: 0,
                      borderRadius: "30%",
                      cursor: "pointer",
                      float: "right",
                    }}
                    onClick={handleDownload}
                  >
                    <DownloadIcon style={{ width: 30, height: 30 }} />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {loading && (
        <div className="progress-bar">
          <div className="progress-bar-fill"></div>
        </div>
      )}
    </motion.div>
  );
};

export default ImageCompressor;
