import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { ReactComponent as DownloadIcon } from "../../assets/download-square.svg";
import JSZip from "jszip";
import FileSaver from "file-saver";
import "./ImageTypeConverter.css";

const ImageTypeConverter = ({ type, title }) => {
  const [files, setFiles] = useState([]);
  const [imageType, setImageType] = useState("jpeg");
  const [convertedImages, setConvertedImages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allowBulkConversion, setAllowBulkConversion] = useState(type);
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  // Reset state when component mounts or when type/title props change
  useEffect(() => {
    setTriggerAnimation(true); // Trigger animation
    const timer = setTimeout(() => {
      setTriggerAnimation(false); // Reset animation trigger
    }, 1000); // Duration of the animation or longer

    setFiles([]);
    setConvertedImages([]);
    setError(null);
    setLoading(false);
    setImageType("jpeg");
    setAllowBulkConversion(type);

    return () => clearTimeout(timer);
  }, [type, title]);

  const handleFileChange = async (event) => {
    event.preventDefault();

    const selectedFiles = Array.from(event.target.files);

    if (!allowBulkConversion && selectedFiles.length > 1) {
      setError(
        "Bulk conversion is not allowed. Please upload only 1 image at a time."
      );
      setFiles([]);
      setConvertedImages([]); // Clear previous results
      return;
    }

    const validTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/gif",
      "image/bmp",
      "image/tiff",
    ];

    const invalidFiles = selectedFiles.filter(
      (file) => !validTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      setError(
        "Some files are not valid image types. Please upload JPEG, PNG, WebP, GIF, BMP, or TIFF images."
      );
      setFiles([]);
      setConvertedImages([]); // Clear previous results
      return;
    }

    setFiles(selectedFiles);
    setError(null);

    try {
      setLoading(true);
      await convertImages(selectedFiles);
    } catch (err) {
      setError("Failed to convert images. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const convertImages = async (files) => {
    try {
      const promises = files.map(async (file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = async (e) => {
            const img = new Image();
            img.onload = async () => {
              try {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                  if (blob) {
                    const convertedUrl = URL.createObjectURL(blob);
                    resolve({
                      url: convertedUrl,
                      name: `${file.name.split(".")[0]}.${imageType}`,
                      size: blob.size,
                    });
                  } else {
                    reject(new Error("Conversion failed"));
                  }
                }, `image/${imageType}`);
              } catch (conversionError) {
                reject(new Error("Error processing image"));
              }
            };
            img.src = e.target.result;
          };
          reader.onerror = () => reject(new Error("Error reading file"));
          reader.readAsDataURL(file);
        });
      });

      const results = await Promise.all(promises);
      setConvertedImages(results);
    } catch (err) {
      setError("Image conversion failed.");
      throw err;
    }
  };

  const handleDownload = (url, name) => {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.click();
    } catch (err) {
      setError("Download failed. Please try again.");
    }
  };

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();
      const promises = convertedImages.map(async (img) => {
        const response = await fetch(img.url);
        const blob = await response.blob();
        zip.file(img.name, blob);
      });

      await Promise.all(promises);

      zip.generateAsync({ type: "blob" }).then((content) => {
        FileSaver.saveAs(content, "images.zip");
      });
    } catch (err) {
      setError("Failed to create ZIP file. Please try again.");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {title} - Convert Images to Any Format | TechPulsar
        </title>
        <meta
          name="description"
          content="Convert images to various formats like PNG, JPEG, GIF, and more with TechPulsar's Image Converter. Fast and easy image conversion for free."
        />
        <meta
          name="keywords"
          content="image converter, convert images, PNG to JPEG, JPEG to PNG, image format conversion, image conversion tool, TechPulsar tools"
        />
        <meta
          property="og:title"
          content="Image Converter - Convert Images to Any Format | TechPulsar"
        />
        <meta
          property="og:description"
          content="Easily convert images to multiple formats like PNG, JPEG, GIF, and more with TechPulsar's Image Converter. Quick and reliable image conversion."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://techpulsar.com/image-converter"
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/images/image-converter-tool.png"
        />
      </Helmet>
      <motion.div
        key={`${type}-${title}`}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, scale: 0.4 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <Container className="image-type-converter-container bg-dark">
          <Row>
            <Col xs={12}>
              <h1 className="image-type-converter-heading">{title}</h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <div className="row gap-2 p-3">
                {["jpeg", "png", "webp", "gif", "bmp", "tiff"].map((type) => (
                  <Col
                    key={type}
                    className={`image-type-converter-button ${
                      imageType === type ? "selected" : ""
                    }`}
                    onClick={() => setImageType(type)}
                  >
                    {type.toUpperCase()}
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <div className="image-type-converter-input-container">
                <input
                  type="file"
                  accept="image/*"
                  multiple={allowBulkConversion}
                  onChange={handleFileChange}
                  className="custom-file-input"
                />
              </div>
            </Col>
          </Row>

          {/* Display error message if there is an error */}
          {error && (
            <Row>
              <Col>
                <div className="error-message">{error}</div>
              </Col>
            </Row>
          )}
        </Container>

        {/* Display spinner when loading */}
        {loading && (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        )}

        <Container>
          <Row>
            <Col xs={12}>
              {!loading && convertedImages.length > 0 && (
                <motion.div
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 300 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className="image-type-converter-results-container bg-dark rounded-2"
                >
                  <Table
                    striped
                    bordered
                    hover
                    className="image-type-converter-results-table"
                  >
                    <thead>
                      <tr>
                        <th>Original Image</th>
                        <th>Converted Image</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {convertedImages.map((img, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <img
                                src={URL.createObjectURL(files[index])}
                                alt={`Original ${index}`}
                                className="image-type-converter-image"
                              />
                              <div>{files[index].type}</div>
                            </td>
                            <td>
                              <img
                                src={img.url}
                                alt={`Converted ${index}`}
                                className="image-type-converter-image"
                              />
                              <div>{imageType}</div>
                            </td>
                            <td>
                              <button
                                className="image-type-converter-download-button"
                                onClick={() =>
                                  handleDownload(img.url, img.name)
                                }
                              >
                                <DownloadIcon className="image-type-converter-download-icon" />
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          {allowBulkConversion &&
                            convertedImages.length > 0 &&
                            !loading && (
                              <Button
                                variant="primary"
                                onClick={handleDownloadAll}
                                className="mt-3"
                              >
                                Download All as ZIP
                              </Button>
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </motion.div>
              )}
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default ImageTypeConverter;
