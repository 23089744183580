import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Container, Button, Card } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import "./OgTagChecker.css";

function OgTagChecker() {
  const [url, setUrl] = useState("");
  const [ogData, setOgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchOgTags = async () => {
    if (!url.trim()) {
      setError("Please enter a valid URL.");
      return;
    }

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w .-]*)*\\/?$"
    );
    if (!urlPattern.test(url)) {
      setError("Invalid URL format. Please enter a valid URL.");
      return;
    }

    setLoading(true);
    setError(null);
    setOgData(null);

    const minLoaderDuration = 1500; // 1500 milliseconds

    const loaderTimeout = new Promise((resolve) =>
      setTimeout(resolve, minLoaderDuration)
    );

    try {
      const response = await fetch(
        `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`
      );

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Domain not found.");
        } else if (response.status === 403) {
          throw new Error("Access denied (403).");
        } else if (response.status === 502) {
          throw new Error("Bad Gateway (502).");
        } else {
          throw new Error("Site is inaccessible.");
        }
      }

      const text = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      const ogTags = {
        openGraph: {},
        twitterCard: {},
        otherMeta: {},
      };

      doc
        .querySelectorAll('meta[property^="og:"], meta[name^="twitter:"]')
        .forEach((meta) => {
          const property =
            meta.getAttribute("property") || meta.getAttribute("name");
          const content = meta.getAttribute("content");
          if (property && content) {
            if (property.startsWith("og:")) {
              ogTags.openGraph[property] = content;
            } else if (property.startsWith("twitter:")) {
              ogTags.twitterCard[property] = content;
            } else {
              ogTags.otherMeta[property] = content;
            }
          }
        });

      if (
        Object.keys(ogTags.openGraph).length === 0 &&
        Object.keys(ogTags.twitterCard).length === 0 &&
        Object.keys(ogTags.otherMeta).length === 0
      ) {
        setError("No OG tags found.");
      } else {
        setOgData(ogTags);
      }
    } catch (err) {
      if (err.message === "Failed to fetch") {
        setError("Domain not found or site is inaccessible.");
      } else {
        setError(err.message);
      }
    } finally {
      await loaderTimeout;
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>OG Tag Checker - Validate Open Graph Tags | TechPulsar</title>
        <meta
          name="description"
          content="Use TechPulsar's OG Tag Checker to validate and preview your Open Graph tags for social media optimization. Ensure your tags are correct and enhance sharing."
        />
        <meta
          name="keywords"
          content="OG Tag checker, Open Graph tag validator, social media tags, meta tags, Open Graph preview, TechPulsar tools"
        />
        <meta
          property="og:title"
          content="OG Tag Checker - Validate Open Graph Tags | TechPulsar"
        />
        <meta
          property="og:description"
          content="Validate and preview your Open Graph tags for social media optimization with TechPulsar's OG Tag Checker tool. Improve your link sharing experience."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://techpulsar.com/og-tag-checker"
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/images/og-tag-checker-tool.png"
        />
      </Helmet>
      <motion.div
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, scale: 0.4 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <Container className="og-tag-checker bg-dark my-4">
          <h1>OG Tag Checker</h1>
          <div className="form-group1">
            <label htmlFor="url">Enter URL:</label>
            <input
              type="text"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com"
              style={{ width: "100%", marginBottom: "2%" }}
            />
            <Button onClick={fetchOgTags} disabled={loading}>
              {loading ? "Checking..." : "Check OG Tags"}
            </Button>
          </div>

          {error && <p className="error">{error}</p>}
        </Container>

        {loading && (
          <div className="loader-container">
            <FaSpinner className="spinner" />
          </div>
        )}

        {ogData && !loading && (
          <Container>
            <div className="row gap-row">
              {/* Open Graph Tags */}
              <motion.div
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 300 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="col-md-6"
              >
                <Card className="og-tag-checker bg-dark p-0">
                  <Card.Body>
                    <h1>
                      Open Graph Tags ({Object.keys(ogData.openGraph).length}{" "}
                      found)
                    </h1>
                    {Object.keys(ogData.openGraph).length > 0 ? (
                      <table>
                        <thead>
                          <tr>
                            <th>Property</th>
                            <th>Content</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(ogData.openGraph).map(
                            ([key, value]) => (
                              <tr key={key}>
                                <td>{key}</td>
                                <td>{value}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <p>No Open Graph tags found.</p>
                    )}
                  </Card.Body>
                </Card>
              </motion.div>

              {/* Twitter Card Tags */}
              <motion.div
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 300 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                className="col-md-6"
              >
                <Card className="og-tag-checker bg-dark p-0">
                  <Card.Body>
                    {Object.keys(ogData.twitterCard).length > 0 ? (
                      <>
                        <h1>
                          Twitter Card Tags (
                          {Object.keys(ogData.twitterCard).length} found)
                        </h1>
                        <table>
                          <thead>
                            <tr>
                              <th>Property</th>
                              <th>Content</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(ogData.twitterCard).map(
                              ([key, value]) => (
                                <tr key={key}>
                                  <td>{key}</td>
                                  <td>{value}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <p>No Twitter Card tags found.</p>
                    )}
                  </Card.Body>
                </Card>
              </motion.div>

              {/* Other Meta Tags */}
              <motion.div
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 300 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 2 }}
                className="col-md-6"
              >
                <Card className="og-tag-checker bg-dark p-0">
                  <Card.Body>
                    {Object.keys(ogData.otherMeta).length > 0 ? (
                      <>
                        <h1>
                          Other Meta Tags (
                          {Object.keys(ogData.otherMeta).length} found)
                        </h1>
                        <table>
                          <thead>
                            <tr>
                              <th>Property</th>
                              <th>Content</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(ogData.otherMeta).map(
                              ([key, value]) => (
                                <tr key={key}>
                                  <td>{key}</td>
                                  <td>{value}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <p>No other meta tags found.</p>
                    )}
                  </Card.Body>
                </Card>
              </motion.div>
            </div>
          </Container>
        )}
      </motion.div>
    </>
  );
}

export default OgTagChecker;
