import React, { useState } from "react";
import { motion } from "framer-motion";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Header.css"; // Ensure CSS file is imported

function capitalizeFirstLetter(str) {
  const lowerCaseExceptions = [
    "a",
    "an",
    "and",
    "but",
    "for",
    "in",
    "of",
    "or",
    "the",
    "to",
    "with",
  ];

  return str
    .split(/[\s-]/)
    .map((word, index) =>
      index === 0 || !lowerCaseExceptions.includes(word.toLowerCase())
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase()
    )
    .join(" ");
}

export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const normalizedCurrentPath = currentPath.toLowerCase();

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      expanded={expanded}
      className="header"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src="/images/T-white.svg"
              alt="Logo"
              width={120}
              style={{ marginRight: "10px" }}
            />
          </motion.div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <Nav className="ml-auto">
              {[
                "/image-resizer",
                "/image-compression",
                "/QR-code-Generator",
                "/CSS-minifier",
                "/oG-tag-Checker",
                "/Excel-to-CSV",
                "/color-picker",
              ].map((path, index) => (
                <Nav.Link
                  as={Link}
                  to={path}
                  key={index}
                  onClick={() => setExpanded(false)}
                  className={
                    normalizedCurrentPath === path.toLowerCase() ? "active" : ""
                  }
                >
                  {capitalizeFirstLetter(path.split("/")[1].replace("-", " "))}
                </Nav.Link>
              ))}
              <NavDropdown
                title="Image Converter"
                id="image-converter-dropdown"
                className={
                  normalizedCurrentPath.includes("/image-converter")
                    ? "active"
                    : ""
                }
              >
                <NavDropdown.Item
                  as={Link}
                  to="/image-converter"
                  onClick={() => setExpanded(false)}
                >
                  Image Converter
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/bulk-image-converter"
                  onClick={() => setExpanded(false)}
                >
                  Bulk Images Converter
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </motion.div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
