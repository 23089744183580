import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "../App";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import ImageResizer from "../pages/ImageResizer/ImageResizer";
import ImageCompressor from "../pages/ImageCompressor/ImageCompressor";
import QrCodeGen from "../pages/QrCodeGen/Qrcode";
import CssMinifier from "../pages/CSSMinifier/CssMinifier";
import OgTagChecker from "../pages/OgTagChecker/OgTagChecker";
import ExcelToCsvJsonConverter from "../pages/ExcelToCsv/ExcelToCsvConverter";
import ImageTypeConverter from "../pages/ImageFormatConverter/ImageTypeConverter";
import ColorPicker from "../pages/ColorPicker/ColorPicker";

const RouterSetup = () => {
  return (
    <Router>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/image-resizer" element={<ImageResizer />} />
          <Route path="/image-compression" element={<ImageCompressor />} />
          <Route path="/QR-code-Generator" element={<QrCodeGen />} />
          <Route path="/css-minifier" element={<CssMinifier />} />
          <Route path="/og-tag-checker" element={<OgTagChecker />} />
          <Route path="/Excel-to-CSV" element={<ExcelToCsvJsonConverter />} />
          <Route
            path="/image-converter"
            element={
              <ImageTypeConverter type={false} title={"Image Converter"} />
            }
          />
          <Route
            path="/bulk-image-converter"
            element={
              <ImageTypeConverter type={true} title={"Bulk Images Converter"} />
            }
          />
          <Route path="/color-picker" element={<ColorPicker />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default RouterSetup;
