import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { minify } from "csso";
import { motion } from "framer-motion";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";

const CssMinifier = () => {
  const [cssInput, setCssInput] = useState("");
  const [minifiedCss, setMinifiedCss] = useState("");
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  // Basic CSS validation function
  const isValidCss = (css) => {
    const balancedBraces =
      (css.match(/{/g) || []).length === (css.match(/}/g) || []).length;
    const hasCssStructure = /[a-zA-Z0-9\s.#:\[\]>~]+{[^}]+}/.test(css); // Basic CSS pattern
    return balancedBraces && hasCssStructure;
  };

  // Check if CSS is already minified
  const isCssMinified = (css) => {
    // If there are no line breaks and very few spaces, it's likely minified
    const hasFewSpaces = css.split(" ").length / css.length < 0.01; // Low space density
    const hasFewLineBreaks = css.split("\n").length < 2; // Fewer line breaks
    return hasFewSpaces || hasFewLineBreaks;
  };

  const handleMinify = () => {
    if (!isValidCss(cssInput)) {
      setError("Invalid CSS input. Please check your syntax.");
      return;
    }

    if (isCssMinified(cssInput)) {
      setError("CSS is already minified.");
      return;
    }

    try {
      const result = minify(cssInput, {
        restructure: true,
        sourceMap: false,
        debug: false,
      });
      setMinifiedCss(result.css);
      setError("");
    } catch (err) {
      setError("An error occurred while minifying the CSS.");
      console.error(err);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(minifiedCss).then(
      () => {
        setCopySuccess(true); // Show the acknowledgment
        setTimeout(() => setCopySuccess(false), 2000); // Hide after 2 seconds
      },
      (err) => {
        setError("Failed to copy CSS: " + err);
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>CSS Minifier Tool - TechPulsar</title>
        <meta
          name="description"
          content="Use TechPulsar's CSS Minifier to compress and optimize your CSS code instantly. Improve website performance with our easy-to-use tool."
        />
        <meta
          name="keywords"
          content="CSS minifier, compress CSS, optimize CSS, CSS compressor, minify CSS, TechPulsar tools"
        />
        <meta
          property="og:title"
          content="CSS Minifier Tool - Compress Your CSS Code Instantly | TechPulsar"
        />
        <meta
          property="og:description"
          content="Compress your CSS code quickly and easily using our CSS Minifier tool. Enhance website speed and performance by reducing file sizes."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://techpulsar.com/css-minifier" />
        <meta
          property="og:image"
          content="https://techpulsar.com/images/css-minifier-tool.png"
        />
      </Helmet>
      <motion.div
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, scale: 0.4 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        className="container rounded bg-dark my-4 p-4"
      >
        <Row>
          <Col md={12}>
            <h1 className="text-center mb-4">CSS Minifier</h1>
            <Form>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="cssInput">
                    <Form.Label>Enter your CSS here:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      value={cssInput}
                      onChange={(e) => setCssInput(e.target.value)}
                      placeholder="Enter your CSS here..."
                      style={{ resize: "none" }}
                    />
                  </Form.Group>
                  <Button
                    onClick={handleMinify}
                    variant="primary"
                    className="mt-3 w-100"
                  >
                    Minify CSS
                  </Button>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="minifiedCss">
                    <Form.Label>Minified CSS:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      value={minifiedCss}
                      readOnly
                      style={{ resize: "none" }}
                    />
                  </Form.Group>
                  <Button
                    onClick={handleCopyToClipboard}
                    variant="success"
                    className="mt-3 w-100"
                  >
                    Copy Minified CSS
                  </Button>
                  {copySuccess && (
                    <Alert variant="success" className="mt-2">
                      CSS copied to clipboard!
                    </Alert>
                  )}
                </Col>
              </Row>
            </Form>
            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}
          </Col>
        </Row>
      </motion.div>
    </>
  );
};

export default CssMinifier;
