import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import RouterSetup from "./Router/Router";
import 'bootstrap/dist/css/bootstrap.min.css';
// import App from "./App";
// import Attribution from './components/Attribution';
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterSetup />
    {/* <Attribution /> */}
  </React.StrictMode>
);

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
