import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import Card from "./components/Card";
// import "./App.css";

const App = () => {
  return (
    <Container className="mt-4 text-center">
      <Row>
        <Col>
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="display-4 text-white mb-0"
            style={{ fontSize: 45 }}
          >
            Welcome to TechPulsar Tools
          </motion.h1>
        </Col>
      </Row>
      <Row className="m-0">
        <Col>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
            className="text-white"
          >
            Optimize your workflow with our cutting-edge image and utility
            tools.
          </motion.p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card />
        </Col>
      </Row>
    </Container>
  );
};

export default App;
